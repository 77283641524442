import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../../components/Card';
import { ButtonGhost } from '../../../../components/Button';
import { designTokens } from '../../../../components/Theme/designTokens';
import projects from '../../../../data/projects';
import { IconBox, IconCard } from '../../../../components/IconBox';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { OutboundLink } from '../../../../components/StyledLink';
import { CallToAction } from '../../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Chargebacks911" mdxType="SEO" />
    <IntroProject projectName="Internal Management Tool" projectDescription="A client management tool connected to large external datasets" projectYear="2016 - 2019" projectImage="cb911-ers-preview" subProject="Chargebacks911" subProjectLink="chargebacks911" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Overview 📋</h5>
        <p>We use an internal web application to manage merchant accounts, clients, and their user permissions. Our internal teams use this application to:</p>
        <ul>
          <li>View client cases</li>
          <li>Add documents to case files</li>
          <li>Manage permissions</li>
          <li>Capture screenshots and data from a custom browser</li>
        </ul>
        <p>As the oldest application of the company, and one that houses a complex portion of the payments process, we thought we could make the user processes easier and refactor the UI's framework by:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Strengthening visual hierarchy and clarifying the navigation, including designing a new icon set that is extensible and legible at small sizes</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Reduce and eliminate inconsistencies by establishing conventions for how pages should be laid out, how buttons should be colored and positioned depending on their purpose, and how copy should be written</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Finding and implementing a more appropriate interface font that prioritized load speed and legibility at smaller sizes</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Cleaning up and organizing the CSS, establishing and enforcing naming rules in order to help maintain consistency, reduce technical debt, and simplify future changes</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Problem:</Text>
            <p className="lead">How can we organize the features of a complex tool while increasing the efficiency of development?</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Our focus on redesigning the components</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAACE4AAAhOAFFljFgAAABwElEQVQoz62RT0iTYRzHd/E2kroE/dHDLirayVt5FyNFZdBkbROR1a2OncI/TSFPEqloXhIiezehHcJ/TKehjS1WOrf03UYOZbg/yMhJbu/76fUt1xTr1Be+z+/5we/5/J7n+WlkWeZ/6ISjOV7ExHdssyK2uTB9Re6Z2eLFcgwxnaDfO0Wvx0GPR6D7o6Ds7aoHPjlJZjMFqAqc+hLnwuNZrnct/LaL8m4XV57MU923grDhRzPYysWRdq6+tHJt/D6XxzrRDpsoeW5gLbmtAvOy9AvoXI9TqgCr+t3oehfQPV2k8tkHym1uagY8vAsH0Y6aKR3tQDfxkIpXjygbf4B26B6XlCaBVOw0MJ45xOHfRfDvYP+sRF+MSXeQ164A71dFdndi2H0zvPVO88Y3zWRoGWFzBcfmKs6wl8yP7J8nnzuU4BrJ5jukDXoS+ma2GxtJ6e+SbNGTNlogtf/voRwnOUnxUQ5JyffdS2zU3OBrXR1iw20iTU1s1dcTqq0ldOsmB5GwWpfL55Rz+QKsMJRCF0lSYzYQINLWRtRoJGoyEbVYiJrNRAwGvlmtHO3tnaovluZvVz/5CPkcF9ed1U+HE1/HByIXAAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/7b3ea2583620fa89479d927d8edd36a3/d9199/cb911-ers-9.png",
          "srcSet": ["/static/7b3ea2583620fa89479d927d8edd36a3/8ff5a/cb911-ers-9.png 240w", "/static/7b3ea2583620fa89479d927d8edd36a3/e85cb/cb911-ers-9.png 480w", "/static/7b3ea2583620fa89479d927d8edd36a3/d9199/cb911-ers-9.png 960w", "/static/7b3ea2583620fa89479d927d8edd36a3/07a9c/cb911-ers-9.png 1440w", "/static/7b3ea2583620fa89479d927d8edd36a3/29114/cb911-ers-9.png 1920w", "/static/7b3ea2583620fa89479d927d8edd36a3/97a96/cb911-ers-9.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Background 📚</h5>
        <p>When I first joined Chargebacks911, there was no design system; there was only <i>years</i> of design debt: stacks of duplicate code, and no design file assets 🤷‍♀️.</p>
        <p>It’s difficult to create space for systematizing design – especially when you’re juggling multiple projects and quickly iterating on the handful of projects at the company.</p>
        <p>While we would typically use qualitative research methods on our client-facing tools, fixing an internal tool might require a different mindset. I took the pain points I would regularly experience from the engineers on my team and began to try and fix them:</p>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>"Can I get this icon?"</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>"Is there a pattern for this experience?"</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>"The design uses a different shade of blue? Can I just use this one?"</p>
        </Box>
        <p>So I started with a quick UI audit and began putting together a functional design system that would help us alleviate the need for asking these sorts of questions.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Atoms of the design system</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAACE4AAAhOAFFljFgAAACf0lEQVQ4y42Ty08TURSH+atMfCzcuEHjwgXGmLiRkKjRhYkaEFxhoWyMj4UpYACjkgAhgZrQSCghPELRIggtLZY+ZvoufWDpc2Y+p1Mqpa3KJGfuvefe+eZ3zj2nSVEUap96j+pTGnnrn6bSK5WTWBSyzHszeJIFkCWKxSKSJGkmy/IfaAVcPa/+oQac3Ipx6ZPIhfciHXNh9sNBnM5dBFHE4XCQz+e1D0rg6rF6XoFqwHcrXi4O2rkx7ub2mJ1CLqMpKwWfy2VPFeoJhaYND+f6ZjnT+4Vu4waRcJhth4vltXVsTher1k3Wt+ysfP3OksXKxvaOtjaZF7Fu2shmcycV5rNZQgE/guDjMH1ALJ7AKwSw/rDh9omEozE8gp/dPS9CIKQCHURi+zhcbpx7HjUlhWNgo9tLpA4Q/EH8wXAZLgZIH2ZOH/JxgsvO1K80gXCEUCRKdD+ujZlsOZelM9WXU1rXXUoFWNk4UIFBFRKKxIgnkiRVxaUyqj3byJqqJVeAsXhSzVkAn5qvaCKlFXpRVSIdqStIxbKV6rViilwPbNwR0n96qEGnVEDSUUcYLTs86DfROW6hc9pG96wH/VIEo1th3vaN+yN9tH/uV5tgiC7LKN22Kfp9i+TkYmOgwWTl7NOPXHk5R7PBSsvoLreMYd7aYGzNzHn9HZpHnnF5UsdV8wtarAM8dhnJyIWaHMrl8H6aP7Cgu4bd0EpguJX05EMUU5e6MYEwM8PC9ZvY2+4itt3j8FE7PNfDwJBa0PlaYFmhZ22G1cEnOCd0+Kd7SM69Ir9iAN88geVlVjs6cep6EXv0JF+/oTA0jDw1jVKoUngMlv9evP8q7KPxN2KbootiN+cdAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/614539ce787655b29c17989b7dcce7eb/d9199/cb911-ers-10.png",
          "srcSet": ["/static/614539ce787655b29c17989b7dcce7eb/8ff5a/cb911-ers-10.png 240w", "/static/614539ce787655b29c17989b7dcce7eb/e85cb/cb911-ers-10.png 480w", "/static/614539ce787655b29c17989b7dcce7eb/d9199/cb911-ers-10.png 960w", "/static/614539ce787655b29c17989b7dcce7eb/07a9c/cb911-ers-10.png 1440w", "/static/614539ce787655b29c17989b7dcce7eb/29114/cb911-ers-10.png 1920w", "/static/614539ce787655b29c17989b7dcce7eb/97a96/cb911-ers-10.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Parts of the refactored icons</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.583333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAACE4AAAhOAFFljFgAAABJ0lEQVQoz41RS04CURDkEH5wFFdGwUSRYYbIZ2eMxkt4DkRMFCMDoguVA3gLB+aHGd14J8rqZibBBI2LSr+Xfq+6qjqTuxpDsNEeaV1nXbskWiNkW/Nnd3ZOsHLhKjZnf6fK0x5PMymhwODD3Vsfdi9AdTBB5T5CqRvA6keoPUxgOgH27wKtp8NPHD3FKeliQlG3de2hzA82yQ5JetD1YfVCDohg90OU2JN7/fEdVQ5ZpfLcX4TbNx4agxDHz7GiQpIa73XibBirasvxVeHJyweWm/9QWKTNMm2avUht7XR85BlFoeOhwFp0RGmoUUjOvyo0EoWiSpSIxfPXL+wxtzxJJQqTdmWIZNqgbbG8UOH8lrPpVlmXmm+6LIH0DN26q/0kvx9b/gZ5n08zArw+ugAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/54dcf5ab588d7670c96039f0127df1a8/d9199/cb911-ers-11.png",
          "srcSet": ["/static/54dcf5ab588d7670c96039f0127df1a8/8ff5a/cb911-ers-11.png 240w", "/static/54dcf5ab588d7670c96039f0127df1a8/e85cb/cb911-ers-11.png 480w", "/static/54dcf5ab588d7670c96039f0127df1a8/d9199/cb911-ers-11.png 960w", "/static/54dcf5ab588d7670c96039f0127df1a8/07a9c/cb911-ers-11.png 1440w", "/static/54dcf5ab588d7670c96039f0127df1a8/29114/cb911-ers-11.png 1920w", "/static/54dcf5ab588d7670c96039f0127df1a8/97a96/cb911-ers-11.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Redesign navigation</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAAB1klEQVQ4y52S3U/TUBjG+ZMVNBoJGsMFmHjvP8AFJkYTk91Ax7xABhtXui6abGtBMtuuH6cfa7u2ex/eU9rwkQ2Uk/zytE36y3POeVcQ6rgLBRoQnQG5AWRM8SBU58pdUSmLzyEufuLD9gu821zD2/Vn2Nx4jo2XT5knJW9erTJrVa6SzNeci4XcLrF+YbehYGungc9fG/j46QsOvx+h3T7BUfsYzYMW9vYV7CtNCSnNlvy2SKhjLqX8fnzu4/23M1wYEwz1P7i50jSB7/uIoghhGFKapsiybEnDUEPqDuG5Dv8UgGheSvI8L58tV6DTG+C0P2KGEuqqQ3TVwXJh5mmwTIOlLoQQmE6nLKNSbNge9to/oHR6UE5KqMxO7z7hCKZlYeK4SGcZpKqY10KBJstap/0aqnOJUEfOW1aNEH07w297xqQYhwWsGNANDwcLheryhjNuaE1sOJ4PEYSI4uRWQ+W/he4IwnP57PgWgwBxfH2GpvMYoWxomQi4XZIk1Q1XDR8jzISGv+MxbMcpZ02ufxMGGtVQBQuJt0ye51IUTeXQ0pWvNBILSY5IJSIpeqChjsQeoOBt1ku67mmIG2Nz1Uhy3VAnFlJR5Lea1WkubkitrkqXgy5FVXvQA14AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/d299c75cf01d46799fc32230c5810577/d9199/cb911-ers-13.png",
          "srcSet": ["/static/d299c75cf01d46799fc32230c5810577/8ff5a/cb911-ers-13.png 240w", "/static/d299c75cf01d46799fc32230c5810577/e85cb/cb911-ers-13.png 480w", "/static/d299c75cf01d46799fc32230c5810577/d9199/cb911-ers-13.png 960w", "/static/d299c75cf01d46799fc32230c5810577/07a9c/cb911-ers-13.png 1440w", "/static/d299c75cf01d46799fc32230c5810577/29114/cb911-ers-13.png 1920w", "/static/d299c75cf01d46799fc32230c5810577/97a96/cb911-ers-13.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Component States</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAACE4AAAhOAFFljFgAAACgklEQVQ4y5VT30sUURReivoXIkKC3oSCHqLXoJfAEAopkAh8K6LywehhtyAiUJQtxaAyWkWKRSpUMkNy29xAzF/kz3YTc11Xd9Vdd3Z2fuzcmTlfd2Z2l9WX6sCZe8+Zc785833nuggA8Ye1Fs3am+T4P5pdSdxcheO267puJQvvC24adk5lBmRNt13he5PXmooCU1WtlYjH1gHXRlpEaH4Vc9EkJEmyPxdZT2FkcQ3B+RjWtjNguoHLr8ZxqmUEp5u/oqprBuEuP1bOV2P5Ui2WzlVR+n2vAxiMbKG2awrewFKp/7fTcdzomcUV3xhC4Q07V/kogH31H3Dgdj+ONI1iqrEVkaPHsHj8JOYOV1DyeYcDqDKGVFaEIMvI5XL275mcPN0woeY1MC0PxvdPhiNw9y/A0zeHllAMsS/fkGhsRtzbikRTC+W+TziAwvgEVjz3keh+zUUgR6G9jPOcKKSQzWwhk07CYApkVcGmsIPNTBqiphDjXFuFLj2+AXXwM7TpGd6VI0DRzQK4yjTUfWzDGb8HZ/1u1Azw3w11Y6W9BssvriLyuJpSk31Oh+H0Ol4uBDEUnYUiKyWQckBJU3HCdxP7vRdx0HsBFZ23MNn7EOGGCsy7KzFbf4iSgWcO4Fg8jIZAJ3w/hksDRWWgluV1hgehN7j26SmuD7bDM9qD31MDiPnvIvruHqL+O5QNhxxApkgQE6uQ01tcFAlkmiWwcmBFlCALInI7AojxWVTzSAk5pDJcUEklSzibw8zPEfzqqEN8qK047rt4LALqhs7nUXdWPhkG59vgscFjnTGyYrtDg/OjZbfBlGzpFlGZ0kT0v1dv93gUz+/t8C9OhVr6A0fnrsVNIphXAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/802af15d5faa21ed26ec9077213204e8/d9199/cb911-ers-12.png",
          "srcSet": ["/static/802af15d5faa21ed26ec9077213204e8/8ff5a/cb911-ers-12.png 240w", "/static/802af15d5faa21ed26ec9077213204e8/e85cb/cb911-ers-12.png 480w", "/static/802af15d5faa21ed26ec9077213204e8/d9199/cb911-ers-12.png 960w", "/static/802af15d5faa21ed26ec9077213204e8/07a9c/cb911-ers-12.png 1440w", "/static/802af15d5faa21ed26ec9077213204e8/29114/cb911-ers-12.png 1920w", "/static/802af15d5faa21ed26ec9077213204e8/75ac7/cb911-ers-12.png 3600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Custom Browser and icons</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.833333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAEJwAABCcAFu8l9tAAACYElEQVQoz12Sy08TURSHh2mrLugMuKgVaKMLiSyEGBcYl/4Nbo3/gAtX8jDKAohaSEhNCBIXxETsY2b6wIgrjInrAm1pKJBA2lAWZKbQGehjYvx57m1rlEm+3HNzz/nuPfeO4IicgSEywiy2INDo0SqQFRNXVRNXohbcigWPWkEXzX0JE0LIQrPWatU1EcRwa4GPbNGCk+Z3kgZuxE84Nwl/7AQDiTKGV0/xaM2AJ2byjVlNG1YrMJHYEjpDFQjLJvrjBnq1MvpUkmoG7q7o6FHLXP7k5wkGkzoeftN5Lqv51yG0AzFaRUcCHDH2Cw61AVEhIjVaq6EjWof4mV0H5Sk2OihHTFIuy6fatlRoy1xLJUjjMbhHo+h6mYQ8pkEaUyE9j0BmjLTHKORRBfK4hk6K3ZTjWjr8KxUcIROiZuPy9A/4+m7DT3i9t9Dd3QtJ8kKWr0MiOjs9TdweuN3XCC98vgF4e/pxaXKNurLBXIKTWcPn8L3fxIuZeTx+OoJnr15jKvAOs8FFBOYWONOBIKbfBjH5Zg4TkzOYmJpFcOEjAsEP8C9scAd3uVjfdDf3wvsoxxXsLM6jnFAB/MZp5RRlQ4dhGDg+Poau66hWq7j4PUgeQfhUgStKLif770g4uLyHzS8ryK1+xd73NZyZJra3t5FKpZDNZjnpdBpbW1toNBpN6nXUieHYYVMY4cIz/vxDoX1s5HJYp4L8wQFqtRqOSiUcUFwoFDgsZuzu7iKf30GxWODS+/HSBSGdcIha3lhfJ1LYyed5aznaIJPJ8JOyE7I5ixksLhaLsG37P+EfDrBi4AwHYoIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/e2b5a8c5f369df26b3e2f1cc80586861/d9199/cb911-toro-1.png",
          "srcSet": ["/static/e2b5a8c5f369df26b3e2f1cc80586861/8ff5a/cb911-toro-1.png 240w", "/static/e2b5a8c5f369df26b3e2f1cc80586861/e85cb/cb911-toro-1.png 480w", "/static/e2b5a8c5f369df26b3e2f1cc80586861/d9199/cb911-toro-1.png 960w", "/static/e2b5a8c5f369df26b3e2f1cc80586861/07a9c/cb911-toro-1.png 1440w", "/static/e2b5a8c5f369df26b3e2f1cc80586861/29114/cb911-toro-1.png 1920w", "/static/e2b5a8c5f369df26b3e2f1cc80586861/16bd1/cb911-toro-1.png 2922w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Browser download page</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.58333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAABr0lEQVQoz32S3bKaMBRGff9H6kx7UW/aOehYERJADD8iJFFBEDSQZDfKOWrPtF2zJxcZVr6dsCf4DkIYucj9AD1w/85oTUY1irw0y/K8oJTFcbIhJIrjKE62uyLLaZYXt9qNlW93O9/3zckThLGH8VVcein7fpBSKaXHVUmlT7WuTro566bV3UVfrlr08irCMDT5E3OA53nmWzBoDa+YzTSFKIYsg5wC28OxhKrW5zMx8i35Vf6E2UwSIATSLexyKCjwA5SV6YWs1x8yxibxatvt9+m785CNGa51FGkjHw6aMjiU+liSIHgmG1lQVlhW13bP/o28DqXj1IwNVdWvQ6WkTDOTT3z/RVaqB+B1wxk7lmXTNHqUsa9tR/aD2Gbtl68drU/zFez3G+z90bag9Did9kL0d95lF8HSVptI4KBznPbbYlgGUOQvsufdfo0Qqq4fj2VkPQwmVs8X8GsJjgt+AGkEaaLihGBsBuX/ry1hsYS32U22V+AgcwsIN0Bigu7yOGiMMc75uD6hlFsW//GTv1l8NuPzBV8sub1iKycwI/yQV/8CoVu57qdCd+s3RPDC7VDp8VYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/c6f4d9a7e825429ec0d160c084059ad0/d9199/cb911-toro-2.png",
          "srcSet": ["/static/c6f4d9a7e825429ec0d160c084059ad0/8ff5a/cb911-toro-2.png 240w", "/static/c6f4d9a7e825429ec0d160c084059ad0/e85cb/cb911-toro-2.png 480w", "/static/c6f4d9a7e825429ec0d160c084059ad0/d9199/cb911-toro-2.png 960w", "/static/c6f4d9a7e825429ec0d160c084059ad0/07a9c/cb911-toro-2.png 1440w", "/static/c6f4d9a7e825429ec0d160c084059ad0/5d6a0/cb911-toro-2.png 1580w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Dashboard</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAACFUlEQVQ4y3VUy47bMAzM//9WL70U6KHoocV61028cez4KcuSH/F0hkn22QYY0zLJ0ZCisns6nvHnVGNfNND7WzwKz2ek9Kd59cn/Nk5WMTs9kv0RSXpA6zzq3qNxIzof0I8Rgx+RZjmKukPTD6g7grZR3A3ODSjpF/FOj7zqEOKEeVlI5o3Ix4XrFT+yFl9+nRDpP+Qlms7ZJmOIlhNixLe0xvckxz4XIUs6tw7b5YI4LwwOuOh9ugaX/YjSUe3gkZ0Y27RwjPEkHLQxbVI6/ExPSI83wrLpsa6rqQgMWKhMCibiVPf4+juHp6p+GDFNM+NmTNx8YnxkDNYZWVHjIStfCbdtM6cIo3cIxDwOGP1A1Z7fWSYJLuvC/AkLsS5Xu20rDiRM7oQqGdhs10gFSlqWGetlNcgX59nKu3C9iJRtuUO/F8LkueTpjvYxkMyxVz5MhpkbqI8hBPve9o6tuPtmO5SRvsiYPzwQcb0jVElV26PiSZ7ZhmEMBiVKXVG1NjIFfY49lc/zYNT/fxIqsRZZe4W/kU1SQ/WaNfnVc03DdWwmm4r9qfpcshSem84SNMgaFX1TX2WlUBXktJ3zplrTsK4fCLvhSqhEBWotq7WGXY3XKGmoNYO6HdGUL+a/H8rr2LA8DbWu3hM30ICmx9L6Imh361FWmH3n49U1skNh93n38dJL9h0PLygMquZ/vsfbn8NfAZ8r7jLVRbgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/92f415ddc3717b3eac389de547ea2ea4/d9199/cb911-ers-1.png",
          "srcSet": ["/static/92f415ddc3717b3eac389de547ea2ea4/8ff5a/cb911-ers-1.png 240w", "/static/92f415ddc3717b3eac389de547ea2ea4/e85cb/cb911-ers-1.png 480w", "/static/92f415ddc3717b3eac389de547ea2ea4/d9199/cb911-ers-1.png 960w", "/static/92f415ddc3717b3eac389de547ea2ea4/07a9c/cb911-ers-1.png 1440w", "/static/92f415ddc3717b3eac389de547ea2ea4/7d920/cb911-ers-1.png 1655w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Assigning Permissions</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAACsklEQVQ4y42UW09TQRDH++l8MFFAUKGlpaetvdEbdyIEQTSACSqIWqAFejktILfwIg9qgn4AE155MSiXFij03p729O/sHikl8YFN/tntdPY3s7M7R/Xr+Ax/Ti9xcpHB0XkKx4k0F1sfnStr9l/VxudaKXsYg7FUzOHz111MTr/H/FIYXn+Ay7cUIoUxuxjCtNeP2YUgfIEw97nS3GKQFMKM14edbz84VBVP5uAPRqDR6mFtd0FnMENntKBOY4SmzQit3gS1zoBWPa0FkyKy6Wg2WdthYXto7aWALDlV7DKL8Mo6LHYnOnr64ezohsPThcHRMQTFKMKRZYgkNodEJsUWCInoHxiCp6sPdqcHC6FlfnxVjDIMRtfwhKK5O3sJ1s0jb25tg40KKS1VUJRR/c1GqVzG8IsxmO0uGC12zAfE/wF74OrogdnmwPrmFt9YLErIFSQUS2WUCVIqlTg0l8tjhIBWh4f2OggYqQEur3GIu6sXNlcn2qiGK2sbClCSCCRDkkqQOFSGLFeQzeYw9PwlTDYnBLMdc0siv/VqDa1UQxdl2CxYcLdRjYVwFIVyBYlUlgNSmSyy+TzkSgXJVIaUhqdvEA80Btx/rMW7+QB/PjcuhQFbGLCJgKEozzCbLxJQRoGOzbIEAQuFIjIUwH1rIGUYEFdQO6SKjLxcur4UqmU1w2bdTaC4ugEbvSdWQ7XRhnuPtPwh5/MFnJ4ncHgcx+7+Hlb3vuPnwT4yaeqqkxhcvQNo0plQ39KGGd8VkC4lEFmFYDLTjblRR9HYERrIqbHVQBLQqBHQwKWnjATFTjPzrVfrcafuId589PMWVVrvC7Xe1AymPsxheOI1hscVPRubvNb4P9XYmM/Iq7d4OjqBT9s7ODxLQsUa+iCW4H3IdHh2peTtdKr4/o5f8I/DX2KjV2igKqXUAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/b07d3dd42169798188b3e96ec20ec8d9/d9199/cb911-ers-3.png",
          "srcSet": ["/static/b07d3dd42169798188b3e96ec20ec8d9/8ff5a/cb911-ers-3.png 240w", "/static/b07d3dd42169798188b3e96ec20ec8d9/e85cb/cb911-ers-3.png 480w", "/static/b07d3dd42169798188b3e96ec20ec8d9/d9199/cb911-ers-3.png 960w", "/static/b07d3dd42169798188b3e96ec20ec8d9/07a9c/cb911-ers-3.png 1440w", "/static/b07d3dd42169798188b3e96ec20ec8d9/7d920/cb911-ers-3.png 1655w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Designating Roles</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAACZElEQVQ4y5WU2U9TQRTG+9f5JqiRLkmhtHaH0pZNSDSixTR2b2mhpdxKASsaX0xcYvTBRw0toI8+GKOsUgS6Qhc+z0wXWjFEb/Ll3Dln5jdz5txzRd+20/jx8wBb+xlspI+wSWLvrdr5lT3nY9qsz2diDMYSscDz1+9gd/kwPRtDYCYKbzAMd2Cay+UPYdLpg5OsZ2qm6feFIghGBIRoDfO/fPseG3tHEO0e5hGJLUCuUMHQb0avWofxW3fgcPu4Hri8sDs93DrcNbm9AdyesEGj74OubwA9SjXCxNiiTEQ7BznMP3oCPQUGR8dhNFmRTK2h9alUqihXKm2+1Oo6HcAC68gYdMYBxJaW+TVwYGxxme9mGb7Jd0ymVvmicrmCXKGIfLGILNmjXKEJ/riSgsZgwsDgCG7o+iAsPm4Hag39sAyN8t1Wkim+qFQqIZsvoFquonB8it3DExyflOrAJLTGGlCt/xO4VANah8codTPWP31uplYm2PfMPj7sfsWXw72zlNfWOdBMhzgHjLM7rAe1BLQ73JgV5qnqcwhS1Se8HlgdNtz1exCOCogID3mhdFREo3kISq0B0XjiDFgrigkm6wjECg2uyBS4LJaTutEh6cY1qQLXZUpclTb8cj6HzZX0avl4KhrHdnuV6T7qQDZJSpK0SNyraRszdfWo/w6MJ57CSMdnKUuV2nMLL5JMpUentAfBuYUGMA9hIQEVfdA6uj8WZGl2SBr2YrHUL3V2wTsjgB1OtJnO4BW1jT8U5q1kc/pxj2T7R026qGvuO/HsxRve06LGz4ENNlqa/X/V+Dn8BtMOMPg7PMhEAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/e31904f376140b3b69798afcf595b853/d9199/cb911-ers-4.png",
          "srcSet": ["/static/e31904f376140b3b69798afcf595b853/8ff5a/cb911-ers-4.png 240w", "/static/e31904f376140b3b69798afcf595b853/e85cb/cb911-ers-4.png 480w", "/static/e31904f376140b3b69798afcf595b853/d9199/cb911-ers-4.png 960w", "/static/e31904f376140b3b69798afcf595b853/07a9c/cb911-ers-4.png 1440w", "/static/e31904f376140b3b69798afcf595b853/7d920/cb911-ers-4.png 1655w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Navigation Icons</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABoElEQVQoz2NQaDqAjOQaD8g3HZCu3yfTsF+2Yb9C4wHZxv1AcXmQ1H40xQzIHMWmA8otB5WaD+h3HdHpPKzTcRgoqN1xCGiKYvMBlZaDODXLNx4AaktfcSFi3pnZxx6077odveBM5PwzCYvPVWy65jn9RNqycxJ1+4CmYLcZ6ELL/qNa7Ycs+o/aTDhm0HXYfdoJw+4jQXPPGnUfdph0DOgXRaw2KzUfFKneW735ms/MUxkrLnbuuuU940TS4rP+s075zjwZufBsw9ZrojV7lbDaDDQSaLDPrNNmfSBrgXZaguw/6jD5uNOU45YTjnnNPCVVj9fZC08+rN1yI2D2qcDZp+IWnY2Yd7pn390ph+6FzjtbtO6KWC0Om8HxtD952YWguWfsJh6z6j9q0XcUyFBrPWjac8R20nGvGadAkYc7tA927boZMf9MzorzEfNPA73qM+OEzcTjDpNPxC8537bzJjBQgGqw2wxERt1HNNsPabcf1Gw7qN91GBjgQC7QO0CGRf8x2cb9OBMJxOVAJ0AQkC0LTnDAQAKmPDQ3AxEADJxKLr+2FMoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/0d99aa54f2c5aa275b89fe93434aef30/1cfc2/cb911-ers-6.png",
          "srcSet": ["/static/0d99aa54f2c5aa275b89fe93434aef30/8ff5a/cb911-ers-6.png 240w", "/static/0d99aa54f2c5aa275b89fe93434aef30/e85cb/cb911-ers-6.png 480w", "/static/0d99aa54f2c5aa275b89fe93434aef30/1cfc2/cb911-ers-6.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Error Page UI</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.83333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABf0lEQVQoz7WTy07CQBSGeTJ9DddufAJ3Low7FybEuHHlzoUmxr0UAyFRCojhUjQI0cQQrm2HFnphOr9nSgsmYMICm3ztaTrzn/+cM02kC3U8FrWtILUScaCotY1JrYljYwlFreOp0kRfZxgY45C+HsNW6I3MaI2F7tDA0Byj09eRKTXmDlP5GkqNNizbxmCkw2QMNsWGycA5x7rLcT34PsNw4oIHIiRbfosEyW5Ra4NZE8rM0CVnXcPGaDyBNXXhzTiEECEBbYQzhtd7Rev+ArdnSTzQ/u9OF7myBiUWLNRbEFF2TsEsmD8lQSQWQt9NLY3k0QEOd3ZxvL+Hu+tTWKUbZIsVEtSWDqeOg8nUgef5cD1Z0ozwyVUwzyTilAJKJoer8xOw50uITwW+rVPJ78uSCyQoRWRvYmT/OA9CQekyhGIRC3MXvtmZ95RKyr5EQ5E3OSHp8jeyDX+Rrzah1mlN4wuF2gdUYuUcSqdy4il1Q+TafHXxvjiH//KnbJMf7tNREa0L0bgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/5bed12a043704dd6379228c7feb8bc7e/d9199/cb911-ers-7.png",
          "srcSet": ["/static/5bed12a043704dd6379228c7feb8bc7e/8ff5a/cb911-ers-7.png 240w", "/static/5bed12a043704dd6379228c7feb8bc7e/e85cb/cb911-ers-7.png 480w", "/static/5bed12a043704dd6379228c7feb8bc7e/d9199/cb911-ers-7.png 960w", "/static/5bed12a043704dd6379228c7feb8bc7e/67a79/cb911-ers-7.png 1408w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Email Notification</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "556px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "128.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAAsSAAALEgHS3X78AAAFMUlEQVRIx5WVeUxUVxTGicalmqZtRFvbGKNlYAYGR7CM7DAzKrhU2qS1SVMVtK1al1ZbLW1I0/afNk1tUrvYNLGpgjPMAhiXFAFxQQWCRgS1wgzVqICAzPZmYZgZvp573zwWE9P0Jif3vvve/b3vnnPuuVEjIyP4Py0UCk20YBDhcJi/Y6woNrjR3oYd27Zhc+FGbNq4EZvJ3isqwruFhdi0QZwrXP8OqiosT/yRJIwDizasR1RUFJ6ZMRPTJk3GjClTMXOqaNMnT8GMqdMwid4rZDEo1x9FpcUCi9GICrMJhqNlJKh9TGFgaAi/HTyIw4dLUVamh9FohsVSAb3eAJPJQs8mlJaWwWyuwKf79mGJKhFJiQlQymVQxCzAvLlzkKF+BQP9/aJCwe1GQ0PDqPQHvX1ov/U3Hg4M4u79bthdwui7ysoqqJNVSEtJJrASKqUCCXExUMbG4NbNmyLQIwiora2F0+ODfziEi41NsNDCppYruNTYjNb2G3AKXgTJRUxpEkGSFyUgURHLYXJSuThejs7bt8eA9fX1GA6NIEBAKeZSz+Ln8wf42FheDlV83CgsPvZl8utCDuyQgAIBz589izA5NEgpEBge5mnAHDwyIvZhSg/WKixmLE6Qkw/jI1AZB6oUcWNApvAcKfR4/XC43Hhkd47l1bjcY81ECuMWzoeKoEyhQrYAsgXzoKTxhC2frT8TgTngcLrgcgsUDA8cgo+PXYKHf2wxGZGpVkOblY3c9Axkp6YhIyUFaUuSHwOeqcPAoJPMATsBQ6GwaKSUuWEoIPqQ5d2aFSvxZsEbeH11Adbmr8bq5fnQZWZNBJ4jhW6Pl8MGHQxs50of9j+CkxQ6Sb0EzNPq8NqqtXg1bxWH5WmWQUtqOzvGR7muDvd6+tB1r4fbnfu9PB/v3u+hvp//jPvQWI7lORoUrFxDSvOxatkKrMjVQpOWDmtHhxRlNymsQ9AnIOBxAQEfAl4BPX0D3AV9lOC9NGbNTMdNl5nNt81g+dpl/Ae55EtrZ6cI9BKw+nQN9Fce4NDlO/ij8S6OtXbD6/NT/g1x/3m8okIzRZkFQ4LlaXTQZeUgh4A2Cej3CKg8WY3sX1qQ9mMTkvZfxrrDrY9VEjGNTAYDcmh7+brlHMa2qyXFOUsJaI0A3W4X+bAWQVozRCdlKBhCMCQltmgs0vykGPRczUoCMhjbriYji4CpBLSKQJ/XAz1VleYbXWi52YXmdiua2jrR2GbF5etjfautG9/98BOyabGGVDEY225ueiaf65KAfgL+WVaO041tqG1ux1+XWnGq4SpOnG/BqQtXUU3PNY3X0XDdhq+/3Y/MFDVtO4PDNAyWmo5s9VJ02SSF5MNSg4kvuNgm2qW2Lpy/1hkZ23DhWgeuWLvxzfcHOFCXlcuV5UpAUviPzRZR6PPi4O+HoD9eC+PJOpSfEG382HC8BlU1F1Fc8hX5MJWrY8FgR1AbifJo2rAS8PZb6zD/pXlIkCsgl8VFLHaCxcXIqCDIkZaUhJTERVCrVEhdnIQlSiUFKAeDg4Njd0rJZ8V4evpTmBs9G8/PmoUXZkWLFh3N516cPQezn30OmaRu35492LltK3Zs3YrtW7bg/U1FKDtyBP10BdjtdhHodDrw688H8OUXJSj5vBjFez/B3j278fFHH2L3rp3YtWM7dm7/AMeqKtHb2zu62Ol0wk1HV/B4wOqq3+9H1JPuZTYvVZthKrrM2AIvnRrWs8uNzQXH3cujW+aLqYiyysyrdXhiYv9XG//tv5iNHvdcGXpcAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "asdf",
          "title": "asdf",
          "src": "/static/16e65541eafb1d6cc4871f828c3c74e3/96638/cb911-ers-8.png",
          "srcSet": ["/static/16e65541eafb1d6cc4871f828c3c74e3/8ff5a/cb911-ers-8.png 240w", "/static/16e65541eafb1d6cc4871f828c3c74e3/e85cb/cb911-ers-8.png 480w", "/static/16e65541eafb1d6cc4871f828c3c74e3/96638/cb911-ers-8.png 556w"],
          "sizes": "(max-width: 556px) 100vw, 556px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      